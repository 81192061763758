import { Grid, Stack } from '@mui/material';
import { CliCommands } from './components';
import { ToolCard } from '../../../components';
import { CliCommandsLayout, ScanDialogLayout, FormData, formDataTypes } from '../components';
import React from 'react';
import * as assets from '@assets';
import { Formik, FormikHelpers } from 'formik';
import { allRoutesEnum, globalConstants, globalQueries, globalUtils } from '@shared/duck';
import { openapiUtils } from './duck';
import { useNavigate } from 'react-router-dom';
import { useMedia } from '@shared/hooks';
import { OpenApiTarget } from '@api-client';
import { MuiContentLoader } from '@shared/components';

interface ScanOpenapiProps {
  onCancel: () => void;
}

const ScanOpenapi: React.FC<ScanOpenapiProps> = ({
  onCancel,
}) => {
  const navigate = useNavigate();
  const [cliFlow, setCliFlow] = React.useState(false);
  const [isOpenCliCommand, setOpenCliCommand] = React.useState(false);
  const [isUrlCheckLoading, setUrlCheckLoading] = React.useState(false);
  const isMounted = React.useRef(true);

  const { onCreateScan } = openapiUtils.onSubmit();

  const onSubmit = async (
    values: formDataTypes.FormDataFormValues,
    formikHelpers: FormikHelpers<formDataTypes.FormDataFormValues>,
  ) => {
    if (isOpenCliCommand) {
      navigate(allRoutesEnum.Scans);
    }
    if (cliFlow) {
      if (!(values.target as OpenApiTarget)?.swaggerfile_url && values.isFileUrl && !!values.fileUrl) {
        formikHelpers.setFieldTouched('specUrlFromCommandsPage', true);
        formikHelpers.setFieldValue('specUrlFromCommandsPage', values.fileUrl);
      }
      else {
        formikHelpers.setFieldTouched('specUrlFromCommandsPage', false);
        formikHelpers.setFieldValue('specUrlFromCommandsPage', '');
      }
      formikHelpers.setFieldValue('isSpecUrlFromCommandsPageValid', undefined);
      setOpenCliCommand(true);
      return;
    }
    await onCreateScan(values, formikHelpers);
  };

  globalUtils.useComponentWillUnmount(() => isMounted.current = false);

  const onBackClick = () => {
    cliFlow && setOpenCliCommand(false);
  };

  const openCliFlow = () => setCliFlow(true);
  const closeCliFlow = () => setCliFlow(false);

  const { defaultProject = null, isDefaultProjectLoading } = globalQueries.useGetDefaultProject();

  const { isSmallScreen, isUp } = useMedia();
  const isLargeScreen = isUp('800');

  return (
    <MuiContentLoader
      variant='linear'
      isLoading={isDefaultProjectLoading}
    >
      <Formik<formDataTypes.FormDataFormValues>
        initialValues={{
          project: defaultProject || null,
          createNewTargetCheckbox: false,
          bypassUrlValidationCheckbox: false,
          newTargetName: '',
          baseUrl: '',
          nameExists: undefined,
          fileRequired: true,
          specUrlFromCommandsPage: '',
          configuration: {},
        }}
        onSubmit={onSubmit}
        validateOnChange
        validateOnBlur
        validationSchema={openapiUtils.scanOpenApiValidationSchema}
      >
        {({ handleSubmit, values, isSubmitting, isValid, setFieldValue, validateForm }) => {
          const currTarget = values.target as OpenApiTarget;
          const fileAttached = (!values.isFileUrl && !!values.file && !values.isFileRemoved && !values.isFileChanged)
            || (!!values.isFileUrl && !!values.fileUrl && !values.isFileUrlRemoved
              && (cliFlow ? !!currTarget?.swaggerfile_url : true)
            );
          const isAccessible = values.isUrlAccessible;

          const isTargetNameChanged = !values.createNewTargetCheckbox && values.newTargetName && (currTarget?.name !== values.newTargetName);
          const isTargetLocationChanged = !values.createNewTargetCheckbox && (currTarget?.location !== values.baseUrl);
          const isFileUrlChanged = !!currTarget?.swaggerfile_url && currTarget?.swaggerfile_url !== values.fileUrl;

          const isOneCLiCommand = !isTargetNameChanged && !isTargetLocationChanged && !isFileUrlChanged && fileAttached;

          React.useEffect(() => {
            setFieldValue('fileRequired', !cliFlow);
            if (cliFlow) {
              setFieldValue('isUrlAccessible', true, true);
            }
            validateForm({ ...values, fileRequired: !cliFlow, isUrlAccessible: cliFlow ? true : values.isUrlAccessible });
          }, [cliFlow]);

          const disabled = !isValid || isUrlCheckLoading || isSubmitting || (values.isFileUrl && !values.isFileUrlValid) ||
          (values.specStatus && globalConstants.ERROR_SPEC_STATUSES.includes(values.specStatus) &&
          !(values.isFileUrl ? values.isFileUrlRemoved : values.isFileRemoved ));

          return (
            <ScanDialogLayout
              titleIcon={assets.ApiSvg}
              title={'Scan API Target'}
              onClose={onCancel}
              isOpenCli={cliFlow}
              onSubmit={handleSubmit}
              isSubmitting={isSubmitting}
              disabled={disabled}
              isOpenCommandsPage={isOpenCliCommand}
              onBackClick={onBackClick}
              isUrlAccessible={isAccessible}
              openCliFlow={openCliFlow}
            >
              {!isOpenCliCommand ? (
                <Grid
                  container
                  columnSpacing={2}
                  rowSpacing={2}
                >
                  <Grid item xs={isLargeScreen ? 4 : 12}>
                    <Stack flexDirection='column' spacing='1rem'>
                      <ToolCard
                        title='Web Portal'
                        description='Scan internet-accessible targets from NightVision Cloud.'
                        asset={assets.MonitorSvg}
                        action={closeCliFlow}
                        isActive={!cliFlow}
                      />
                      <ToolCard
                        title='CLI Helper'
                        description='Generate a NightVision CLI Command to scan targets on private networks.'
                        asset={assets.TerminalSvg}
                        action={openCliFlow}
                        isActive={cliFlow}
                      />
                    </Stack>
                  </Grid>
                  <Grid item
                    xs={isLargeScreen ? 8 : 12}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      paddingBottom: isSmallScreen ? '1rem' : '2rem'
                    }}
                  >
                    <FormData
                      cliFlow={cliFlow}
                      openApi={true}
                      openCliFlow={openCliFlow}
                      isAccessible={isAccessible}
                      setUrlCheckLoading={setUrlCheckLoading}
                    />
                  </Grid>
                </Grid>
              ) : (
                <CliCommandsLayout multipleCommands={!isOneCLiCommand}>
                  <CliCommands fileAttached={fileAttached} />
                </CliCommandsLayout>
              )}
            </ScanDialogLayout>
          );
        }}
      </Formik>
    </MuiContentLoader>
  );
};

export default ScanOpenapi;