import React from 'react';
import { Description, Title } from '../../cli-commands.styled';
import { UICodeFragment, DocsLink } from '@shared/components';
import { SupportedLangs } from '@shared/duck/enums/global-enums';
import { useFormikContext } from 'formik';
import { formDataTypes } from '../../../../../components';
import { globalConstants, globalEnums } from '@shared/duck';
import { CliCommandsProps } from '../../cli-commands';
import { OpenApiTarget } from '@api-client';

const GenerateSpec: React.FC<CliCommandsProps> = ({
  fileAttached
}) => {
  const { values } = useFormikContext<formDataTypes.FormDataFormValues>();
  const currTarget = values.target as OpenApiTarget;
  const initTargetName = currTarget?.name;
  const initTargetUrl = currTarget?.location;
  const newTargetName = values.newTargetName;
  const newTargetUrl = values.baseUrl;
  const newFileUrl = values.fileUrl;
  const projectId = values.project?.id;

  const isTargetNameChanged = !values.createNewTargetCheckbox && newTargetName && (initTargetName !== values.newTargetName);
  const isTargetLocationChanged = !values.createNewTargetCheckbox && (initTargetUrl !== newTargetUrl);
  const isFileUrlChanged = !!currTarget?.swaggerfile_url && currTarget?.swaggerfile_url !== newFileUrl && !values.isFileUrlRemoved;

  const isNewTarget = values.createNewTargetCheckbox;
  let step = 1;

  return (
    <Description variant='caption'>
      <br />
      {'Please ensure that your API is running.'}

      {isNewTarget && (
        <>
          <Title>{`Step ${step++}`}</Title>
          {'Let’s create a new target first.'}
          <UICodeFragment code={`# Create target\nnightvision target create ${newTargetName} ${newTargetUrl} -P ${projectId} --type ${globalEnums.ECLITargetType.API}`} language={SupportedLangs.bash} />
        </>
      )}

      {(isTargetNameChanged || isTargetLocationChanged || isFileUrlChanged) && (
        <>
          <Title>{`Step ${step++}`}</Title>
          {'We have noticed that you made changes to the target; let\'s apply those first:'}
          <UICodeFragment
            code={'# Update target\nnightvision target update ' +
              `${initTargetName} ${isTargetNameChanged ? `-n ${newTargetName} ` : ''}` +
              `${isTargetLocationChanged ? `-u ${newTargetUrl} ` : ''}` +
              `${isFileUrlChanged ? (currTarget.swaggerfile_url ? '-s ' : '-f ') + `${newFileUrl} ` : ''}` +
              `-P ${projectId}`}
            language={SupportedLangs.bash} />
        </>
      )}
      {!fileAttached && (
        <>
          <Title>{`Step ${step++}`}</Title>
          {step === 2 ? 'First, move ' : 'Move '}
          {'to the repository containing the code for your API to generate the Swagger spec file. We will extract all endpoints for you.'}

          <Title>{`Step ${step++}`}</Title>
          Use the following command to generate a Swagger file. Replace <i>$LANGUAGE</i> with the language of your code. You can find the supported languages <DocsLink to={globalConstants.DOCS_PATHS.FRAMEWORK_SUPPORT}>here</DocsLink>.
          <UICodeFragment code={`# Swagger Extract\nnightvision swagger extract ./ -t ${isTargetNameChanged || isNewTarget ? newTargetName : initTargetName} --lang $LANGUAGE -P ${projectId}`} language={SupportedLangs.bash} />
        </>
      )}

      <Title>{step === 1 ? '' : `Step ${step++}`}</Title>
      {'Run scan:'}
      <UICodeFragment code={`# Run scan\nnightvision scan ${isTargetNameChanged || isNewTarget ? newTargetName : initTargetName} -P ${projectId} ${values.authentication ? `--auth ${values.authentication.name}` : ''}`} language={SupportedLangs.bash} />

    </Description>
  );
};

export default GenerateSpec;