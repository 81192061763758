import { Scan } from '@api-client';
import React from 'react';
import { GridItem } from '@contentful/f36-components';
import { ExternalGrayLinkIcon } from 'views/components/v1/Icons';
import { allRoutesEnum, globalConstants, globalQueries, globalUtils, globalEnums } from '@shared/duck';
import { StyledExtLink, MuiContentLoader } from '@shared/components';
import dayjs from 'dayjs';
import { Cloud, DownloadIconC, FileIcon, SwaggerIconC, WorldIconC } from '@assets';
import saveAs from 'file-saver';
import { StyledGrid } from './scan-parameters.styled';
import { enqueueSnackbar } from 'notistack';
import { CircularProgress, Stack, Typography } from '@mui/material';
import { muiPalette } from '@shared/general-mui-theme';

interface ScanParametersProps {
  scan?: Scan;
}

const ScanParameters: React.FC<ScanParametersProps> = ({ scan }) => {
  const isWebScan = scan ? scan.target_type.toUpperCase() === globalEnums.ETargetType.URL : true;
  const isCredentialsDeleted = scan?.credentials?.is_deleted;
  const isTargetDeleted = scan?.target?.is_deleted;
  const isProjectDeleted = scan?.project?.is_deleted;
  const isCredFromAnotherProject = scan?.credentials?.project !== scan?.target.project;

  const { getOpenApiSpecUrl } = globalQueries.useGetOpenApiSpecUrlFromScanMutation();

  const [isFileLoading, setIsFileLoading] = React.useState(false);

  const loadOpenApiSpecContent = async (scanId: string) => {
    let content: Blob | undefined = undefined;
    setIsFileLoading(true);
    try {
      const response = await getOpenApiSpecUrl({ id: scanId });
      if (response.status === 200 && response.data.url) {
        const res = await fetch(response.data.url);
        content = await res.blob();
      }
    }
    catch {
      enqueueSnackbar(globalConstants.FAILED_TO_READ_FILE, { variant: 'error' });
    }
    finally {
      setIsFileLoading(false);
    }
    return content;
  };

  const isUrl = Boolean(scan?.swaggerfile_url);
  const isSpecUploaded = !!scan?.swaggerfile_url || !!scan?.swaggerfile_name;

  const swaggerFileUrlName = scan?.swaggerfile_url?.substring(scan?.swaggerfile_url.lastIndexOf('/') + 1);
  const swaggerFileName = scan?.swaggerfile_name || swaggerFileUrlName || 'openapi-swagger.yaml';

  const scannedAt = dayjs(scan?.last_updated_at).format(globalConstants.FULL_MONTH_DATE_TIME);

  const projectLink = allRoutesEnum.ProjectDetails.replace(':projectId', scan?.project.id || '');
  const credentialsLink = allRoutesEnum.AuthenticationDetails.replace(':authenticationId', scan?.credentials?.id || '');
  const targetLink = allRoutesEnum.TargetDetails.replace(':targetId', scan?.target_id || '');

  const initiatedWith = globalUtils.getInitiatedWithName(scan?.initiated_with);

  const [cachedFileContent, setCachedFileContent] = React.useState<Blob | undefined>(undefined);

  const onDownload = async () => {
    if (!scan || isWebScan) {
      return;
    }
    if (scan.swaggerfile_url) {
      // Open spec URL in new tab
      window.open(scan.swaggerfile_url, '_blank');
    } else {
      let content = cachedFileContent;
      if (!content) {
        // Download spec file
        content = await loadOpenApiSpecContent(scan.id);
      }
      if (!content) {
        enqueueSnackbar(globalConstants.FAILED_TO_READ_FILE, { variant: 'error' });
      } else {
        setCachedFileContent(content);
        saveAs(content, swaggerFileName);
      }
    }
  };

  const apiSpecIcon = globalUtils.createCustomIcon(isUrl ? Cloud : FileIcon, {});

  return (
    <Stack
      sx={{
        p: '1.5rem 1rem',
        overflow: 'auto',
        wordBreak: 'break-word,'
      }}
    >
      <MuiContentLoader
        isLoading={!scan}
      >
        <StyledGrid
          columns='minmax(10rem, 15rem) minmax(12rem, 20rem)'
          rows='auto'
          columnGap='spacing2Xs'
          rowGap='spacingM'
        >
          <GridItem>Project</GridItem>
          <GridItem>
            <Stack direction='row' alignItems='start' gap='1rem' justifyContent='space-between'>
              <Typography variant='default' fontWeight={isProjectDeleted ? '500' : '400'} color={muiPalette.grey?.[700]}>
                {scan?.project.name}
                {isProjectDeleted && (
                  <Typography variant='default' color={muiPalette.red?.[600]}>
                    &nbsp;(Deleted)
                  </Typography>
                )}
              </Typography>
              {!isProjectDeleted && <StyledExtLink
                className='external-link'
                icon={<ExternalGrayLinkIcon />}
                alignIcon='end'
                href={projectLink}
                target={'_blank'}
                rel='noopener noreferrer'
              />}
            </Stack>
          </GridItem>

          <GridItem>Base URL(s)</GridItem>
          <GridItem>
            <Stack direction='row' alignItems='start' gap='1rem' justifyContent='space-between'>
              <Typography variant='default' color={muiPalette.grey?.[700]}>
                {scan?.location}
              </Typography>
              <StyledExtLink
                className='external-link'
                icon={<ExternalGrayLinkIcon />}
                alignIcon='end'
                href={scan?.location}
                target={'_blank'}
                rel='noopener noreferrer'
              ></StyledExtLink>
            </Stack>
          </GridItem>

          <GridItem>Target Type</GridItem>
          <GridItem>
            <Stack direction='row' alignItems='start'>
              {isWebScan ? (
                <>
                  <WorldIconC
                    style={{
                      width: '1.125rem',
                      height: '1.125rem',
                    }}
                  />
                  <Typography variant='default' color={muiPalette.grey?.[700]}>
                    Web
                  </Typography>
                </>
              ) : (
                <>
                  <SwaggerIconC
                    style={{
                      width: '1.125rem',
                      height: '1.125rem',
                    }}
                  />
                  <Typography variant='default' color={muiPalette.grey?.[700]}>
                    Open API
                  </Typography>
                </>
              )}
            </Stack>
          </GridItem>

          {!isWebScan && <>
            <GridItem>API Specs</GridItem>
            <GridItem>
              {isSpecUploaded ? (
                <Stack direction='row' alignItems='start' gap='1rem' justifyContent='space-between' width='100%'>
                  <Stack direction='row' alignItems='center'>
                    {apiSpecIcon}
                    <Typography variant='default' color={muiPalette.grey?.[700]}>
                      {swaggerFileName}
                    </Typography>
                  </Stack>
                  {isFileLoading ? (
                    <CircularProgress size={20} />
                  ) : (
                    <StyledExtLink
                      className='external-link'
                      icon={isUrl ? <ExternalGrayLinkIcon /> : <DownloadIconC />}
                      onClick={onDownload}
                      size='small'
                      style={{ minWidth: '1.1rem' }}
                    />
                  )}
                </Stack>
              ) : (
                'N/A'
              )}
            </GridItem>
          </>}

          <GridItem>Date Scanned</GridItem>
          <GridItem>
            <Typography variant='default' color={muiPalette.grey?.[700]}>
              {scannedAt}
            </Typography>
          </GridItem>

          <GridItem>Target ID</GridItem>
          <GridItem>
            <Stack direction='row' alignItems='start' gap='1rem' justifyContent='space-between'>
              <Typography variant='default' fontWeight={isTargetDeleted ? '500' : '400'} color={muiPalette.grey?.[700]}>
                {scan?.target_id}
                {isTargetDeleted && (
                  <Typography variant='default' color={muiPalette.red?.[600]}>
                    &nbsp;(Deleted)
                  </Typography>
                )}
              </Typography>
              {!isTargetDeleted && <StyledExtLink
                className='external-link'
                icon={<ExternalGrayLinkIcon />}
                alignIcon='end'
                href={targetLink}
                target={'_blank'}
                rel='noopener noreferrer'
              />}
            </Stack>
          </GridItem>

          <GridItem>Scan initiated from</GridItem>
          <GridItem>
            <Typography variant='default' color={muiPalette.grey?.[700]}>
              {initiatedWith}
            </Typography>
          </GridItem>

          <GridItem>Authentication</GridItem>
          <GridItem>
            {scan?.credentials ? (
              <Stack direction='row' alignItems='start' gap='1rem' justifyContent='space-between'>
                <Stack direction='column'>
                  <Typography
                    variant='default' fontWeight={isCredentialsDeleted ? '500' : '400'} color={muiPalette.grey?.[700]}
                    flexShrink='0'
                  >
                    {scan?.credentials?.name}
                    {isCredentialsDeleted && (
                      <Typography variant='default' color={muiPalette.red?.[600]}>
                        &nbsp;(Deleted)
                      </Typography>
                    )}
                  </Typography>
                  {scan?.credentials?.description ? (
                    <Typography variant='default' fontSize='0.75rem' color={muiPalette.grey?.[500]}>
                      {scan?.credentials?.description}
                    </Typography>
                  ) : null}
                </Stack>
                {!isCredentialsDeleted && !isCredFromAnotherProject && <StyledExtLink
                  className='external-link'
                  href={credentialsLink}
                  icon={<ExternalGrayLinkIcon />}
                  target={'_blank'}
                  rel='noopener noreferrer'
                  size='small'
                />}
              </Stack>
            ) : (
              <Typography variant='default' color={muiPalette.grey?.[700]}>
                N/A
              </Typography>
            )}
          </GridItem>
        </StyledGrid>
      </MuiContentLoader>
    </Stack >
  );
};

export default ScanParameters;
