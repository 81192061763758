import { allRoutesEnum, globalConstants, globalQueries, globalTypes, globalUtils } from 'shared/duck';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { formDataTypes } from '../../components';
import { GitHubTargetConfiguration, InitiatedWithEnum, OpenApiTarget, ScanRequest } from 'api-client';
import { enqueueSnackbar } from 'notistack';
import { targetExclusionsUtils } from '@shared/components';
import { FormikHelpers } from 'formik';

export const scanOpenApiValidationSchema = Yup.object().shape({
  project: Yup.object().required('Please select a project').nullable(),
  target: Yup.object().nullable()
    .when('newTargetName', {
      is: (newTargetName?: string) => newTargetName && newTargetName?.length > 0,
      then: schema => schema.optional()
        .test('validateName', globalConstants.INVALID_NAME_FORMAT, (_, context) => {
          return globalUtils.validateName(context.parent.newTargetName || '');
        })
        .when('nameExists', {
          is: true,
          then: schema => schema.test('testName', 'This name is already taken in this Project', () => false),
        }),
      otherwise: schema => schema.required('Please enter the name of the target')
        .test('isValid', globalConstants.INVALID_NAME_FORMAT, (value?: any) => {
          return globalUtils.validateName(value?.name || '');
        })
        .when('nameExists', {
          is: true,
          then: schema => schema.test('testName', 'This name is already taken in this Project', () => false),
        }),
    }),
  baseUrl: Yup.string().required('Please enter a URL').nullable()
    .when('bypassUrlValidationCheckbox', {
      is: false,
      then: scheme =>
        scheme
          .test('isValid', 'Invalid format', (value?: string | null) => {
            return globalUtils.validateUrls(value ? [value] : []);
          })
          .when('isUrlAccessible', {
            is: false,
            then: schema => schema.test('isValid', 'URL is not accessible', () => false),
          }),
      otherwise: schema => schema
        .test('isValid', 'Invalid format', (value?: string | null) => {
          return globalUtils.validateUrls(value ? [value] : []);
        }),
    }),
  isUrlAccessible: Yup.boolean().notRequired(),
  newTargetName: Yup.lazy(() => Yup.string()
    .when('target', {
      is: (target?: any) => !!target,
      then: schema => schema.optional(),
      otherwise: schema => schema.required('Please enter the name of the target'),
    })),
  file: Yup.mixed().nullable().when(['isFileUrl', 'fileRequired'], {
    is: (isFileUrl?: boolean, fileRequired?: boolean) => { return !isFileUrl && fileRequired; },
    then: Yup.mixed().required('Please select a file')
      // Additional check when state of isFileRemoved field changed
      .test('isFileRemoved', 'Please select a file', (value: any, context) => {
        return !context.parent.isFileRemoved;
      }),
    otherwise: Yup.mixed(),
  }),
  fileUrl: Yup.string().nullable()
    .test('validation', 'Invalid url format', (value, context) => {
      return value ? context.parent.isFileUrlRemoved ? true : globalUtils.validateUrls([value]) : true;
    })
    .when(['isFileUrl', 'fileRequired'], {
      is: (isFileUrl?: boolean, fileRequired?: boolean) => isFileUrl && fileRequired,
      then: Yup.string().required('Please enter the OpenAPI file location')
        // Additional check when state of isFileUrlRemoved field changed
        .test('isFileUrlRemoved', 'Please enter the OpenAPI file location', (_, context) => {
          return !context.parent.isFileUrlRemoved;
        }),
      otherwise: Yup.string(),
    })
    .when('isFileUrlValid', {
      is: false,
      then: schema => schema.test('', globalConstants.INVALID_YAML_DEFINITION, () => false)
    })
    .when('isFileUrlValid2', {
      is: false,
      then: schema => schema.test('', globalConstants.INACCESSIBLE_URL, () => false)
    }),
  isFileUrlValid: Yup.boolean().when(['isFileUrl', 'fileRequired'], {
    is: (isFileUrl?: boolean, fileRequired?: boolean) => isFileUrl && fileRequired,
    then: Yup.boolean().oneOf([true], 'invalid').required('Please, invalid').nullable(false),
  }),
  isFileUrlValid2: Yup.boolean().when(['isFileUrl', 'fileRequired'], {
    is: (isFileUrl?: boolean, fileRequired?: boolean) => isFileUrl && fileRequired,
    then: Yup.boolean().oneOf([true], 'invalid').required('Please, invalid').nullable(false),
  }),
  nameExists: Yup.boolean().oneOf([false], 'Name must be unique').required('Please test the name').nullable(false),
  createNewTargetCheckbox: Yup.boolean()
    .when(['target', 'newTargetName', 'nameExists', 'enableTestCheckbox'], {
      is: (target?: any, newTargetName?: string, nameExists?: boolean, enableTestCheckbox?: boolean) =>
        !target && !!newTargetName && nameExists !== undefined && !nameExists && enableTestCheckbox,
      then: schema => schema.test('createNewTarget', 'Please check to proceed', (value?: boolean) => {
        return value || false;
      }),
      otherwise: schema => schema.optional(),
    }),
  enableTestCheckbox: Yup.lazy(() => Yup.boolean()
    .when(['target', 'newTargetName', 'nameExists', 'createNewTargetCheckbox'], {
      is: (target?: any, newTargetName?: string, nameExists?: boolean, createNewTargetCheckbox?: boolean) =>
        !target && !!newTargetName && nameExists !== undefined && !nameExists && !createNewTargetCheckbox,
      then: schema => schema.test('checkCreateNewTarget', 'Remove cursor from Target Name field', () => {
        return false;
      }),
    }),
  ),
  specUrlFromCommandsPage: Yup.lazy(() => Yup.string().nullable().notRequired()
    .test('validation', 'Invalid url format', value =>
      value ? globalUtils.validateUrls([value]) : true
    )
    .when(['isSpecUrlFromCommandsPageValid', 'specUrlFromCommandsPage'], {
      is: (isSpecUrlFromCommandsPageValid?: boolean, specUrlFromCommandsPage?: string,) => isSpecUrlFromCommandsPageValid === false && !!specUrlFromCommandsPage,
      then: schema => schema.test('', globalConstants.INVALID_YAML_DEFINITION, () => false)
    })
    .when(['isSpecUrlFromCommandsPageValid2', 'specUrlFromCommandsPage'], {
      is: (isSpecUrlFromCommandsPageValid2?: boolean, specUrlFromCommandsPage?: string,) => isSpecUrlFromCommandsPageValid2 === false && !!specUrlFromCommandsPage,
      then: schema => schema.test('', globalConstants.INACCESSIBLE_URL, () => false)
    })),
  configuration: targetExclusionsUtils.TargetExclusionsValuesSchema,
});

export const onSubmit = () => {
  const navigate = useNavigate();

  const { onCreate } = globalUtils.onCreateNewTarget();
  const { onUpdate } = globalUtils.onUpdateNewTarget();
  const { waitTargetForReadyToScan } = globalUtils.waitTargetForReadyToScanHandler();
  const { createScans } = globalQueries.useCreateScans();

  const onCreateScan = async (values: formDataTypes.FormDataFormValues,
    formikHelpers: FormikHelpers<formDataTypes.FormDataFormValues>) => {
    try {
      const currTarget = (values.target as OpenApiTarget);

      // Initialization
      const scanRequest: ScanRequest =
      {
        credentials_id: values.authentication?.id as string | null,
        target_id: currTarget?.id || '',
        initiated_with: InitiatedWithEnum.Web,
      };

      const urlPatterns = values.configuration.excluded_url_patterns?.trim();
      const xPaths = values.configuration.excluded_x_paths?.trim();
      const configuration: GitHubTargetConfiguration = {
        excluded_url_patterns: urlPatterns ? urlPatterns.split('\n') : [],
        excluded_x_paths: xPaths ? xPaths.split('\n') : [],
      };

      // Create new Target
      if (values.createNewTargetCheckbox) {
        const targetToCreate: globalTypes.NewOpenApiTarget = {
          name: values.newTargetName || '',
          location: values.baseUrl || '',
          project: values.project?.id || '',
          file: !values.isFileUrl ? values.file || undefined : undefined,
          fileLocation: values.isFileUrl ? values.fileUrl || '' : '',
          internet_accessible: values.bypassUrlValidationCheckbox ? true : values.isUrlAccessible,
        };

        const res = await onCreate(targetToCreate, !!values.isFileUrl, configuration);
        scanRequest.target_id = res.id;
      }

      // Update target if name/url/fileUrl/file/configuration was changed
      const initTargetName = values?.target?.name;
      const initTargetUrl = values?.target?.location;
      const newTargetName = values?.newTargetName;
      const newTargetUrl = values?.baseUrl;

      const isTargetNameChanged = !!newTargetName && (initTargetName !== newTargetName);
      const isTargetLocationChanged = (initTargetUrl !== newTargetUrl);

      const isFileUrlChanged = currTarget?.swaggerfile_url !== values.fileUrl;
      const isExclusionsChanged = JSON.stringify(values.target?.configuration) !== JSON.stringify(values.configuration);

      if (!values?.createNewTargetCheckbox && (isTargetNameChanged || isTargetLocationChanged || isFileUrlChanged || values.isFileChanged)) {
        const targetToUpdate: globalTypes.NewOpenApiTarget = {
          name: isTargetNameChanged ? newTargetName : initTargetName || '',
          location: isTargetLocationChanged ? newTargetUrl || '' : initTargetUrl || '',
          project: values.project?.id || '',
          file: values.isFileChanged ? values.file || undefined : undefined,
          fileLocation: isFileUrlChanged ? values.fileUrl || '' : '',
        };
        await onUpdate(
          targetToUpdate, currTarget?.id || '', configuration,
          values.isFileUrl ?? false, values.isFileChanged, isFileUrlChanged, isExclusionsChanged);
      }

      const { error, aborted } = await waitTargetForReadyToScan(
        scanRequest.target_id,
        status => formikHelpers.setFieldValue('specStatus', status),
        isReady => formikHelpers.setFieldValue('isReadyToScan', isReady)
      );

      if (error) {
        enqueueSnackbar(error, { variant: 'error' });
        return;
      }

      if (!aborted) {
        // Start a scan from WEB flow
        const res = await createScans({ scanRequest: [scanRequest] });
        navigate(allRoutesEnum.ScanDetails.replace(':scanId', res.data[0].id));
      }
    }
    catch (error) {
      enqueueSnackbar('Failed to initiate scanning', { variant: 'error' });
      console.error(error);
    }
  };

  return { onCreateScan };
};