import React from 'react';
import { Route, Routes, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useApp, useLaunchDarklyFlags } from '@shared/hooks';
import { allRoutesEnum, globalTypes } from '@shared/duck';
import { observer } from 'mobx-react';
import { useWorkspaceRouter, useBillingRoutes, NotFoundPage, NoAccessPage, ErrorPage, SSOPage } from '@pages';

const WorkspaceRoutes: React.FC = observer(() => {
  const { freshchat } = useLaunchDarklyFlags();
  const app = useApp();
  const navigate = useNavigate();

  const [canNavigate, setCanNavigate] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (app.users.me?.firstLogin) {
      // Show "Let's begin!" on first login
      navigate(allRoutesEnum.GetStarted, { replace: true });
    }
    else {
      const noFullSubscription = !app.checkHasFullSubscription();
      if (noFullSubscription) {
      // Show "Choose a Plan" if no subscription or API ENVY
        if (app.users.plan) {
          navigate(allRoutesEnum.SelectBillingPlan, { replace: true });
        }
      } else {
        setCanNavigate(true);
      }
    }
  }, [app.users.me?.firstLogin, app.users.plan]);

  React.useEffect(() => {
    app.activateFreshChat(freshchat);
  }, [freshchat, app.users.me]);

  const workspacePages = useWorkspaceRouter();
  const billingPages = useBillingRoutes();

  const [searchParams, setSearchParams] = useSearchParams();

  const [selectedProjects, setSelectedProjects] = React.useState<globalTypes.UIOptionItem<string>[]>([]);
  const [lastPage, setLastPage] = React.useState('');

  const location = useLocation();
  React.useLayoutEffect(() => {
    if (location.pathname !== window.location.pathname) return;
    const projectsParams = searchParams.get('project');
    const currPage = location.pathname;
    if (projectsParams && !!location.search) {
      const newValuesString = projectsParams?.split(',');

      const newValues = newValuesString?.map(filter => {
        const label = filter.split('/')[0];
        const value = filter.split('/')[1];
        return ({
          value: value, label: label, key: value,
        });
      });
      setSearchParams((urlParams) => {
        if (newValues.length) {
          const newSearchValues = newValues.map(filter =>
            `${filter.label}/${filter.value}`).join(',') || '';
          urlParams.set('project', newSearchValues);
          setSelectedProjects(newValues);
        } else {
          urlParams.delete('project');
          setSelectedProjects([]);
        }
        return urlParams;
      }, { replace: true });
    }
    else if (selectedProjects.length
      && currPage !== lastPage
    ) {
      setSearchParams((urlParams) => {
        const newSearchValues = selectedProjects.map(filter =>
          `${filter.label}/${filter.value}`).join(',') || '';
        urlParams.set('project', newSearchValues);
        return urlParams;
      }, { replace: true });
    }
    else {
      setSelectedProjects([]);
    }
    setLastPage(location.pathname);
  }, [location.pathname, searchParams]);

  return (
    /** Note: here we will regulate the conditions for the availability of routes
     * including a free plan and a paid plan (or what ever be in the future)
     */
    <Routes>
      {canNavigate && workspacePages}

      {billingPages}

      <Route path={allRoutesEnum.NoAccess} element={<NoAccessPage />} />

      <Route path={allRoutesEnum.Error} element={<ErrorPage />} />

      <Route path={allRoutesEnum.SSO} element={<SSOPage />} />

      {/* `canNavigate` to fix blinking NotFound page at the fresh start */}
      {canNavigate && <Route path='*' element={<NotFoundPage />} />}
    </Routes>
  );
});

export default WorkspaceRoutes;
